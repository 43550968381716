<template>
    <div class="about">
        <p class="text-center">Sisa Waktu</p>

        <h2 class="text-center">{{ text_duration ? text_duration:'Loading...' }}</h2>
        <div class="text-center mt-4" v-if="text_duration">
            <div class="mt-2">
                <button v-if="!stop" class="btn btn-info" type="button" v-on:click="pauseTimer(pause)">
                    <i class="fa" :class="pause == 0 ? 'fa-pause':'fa-play'"></i> {{pause == '0' ? 'Jeda':'Lanjut'}}
                </button>
            </div>
            <div class="mt-2">
                <button v-if="!stop" class="btn btn-danger" type="button" v-on:click="stopTimer">
                    <i class="fa fa-stop"></i> Berhenti
                </button>
            </div>
            <div class="mt-2">
                <button v-if="stop" class="btn btn-primary" type="button" v-on:click="startTimer">
                    <i class="fa fa-play"></i> Mulai
                </button>
            </div>
        </div>
    </div>
  </template>
  
  <script>
  export default {
    name: 'TimerView',
    metaInfo: {
      title: 'Timer',
      titleTemplate: '%s - Oeltima Experimental'
    },
    data() {
        return {
            current_time : 1602316800,
            current_end  : 1602316810,
            duration     : {
                days : '00',
                hours : '00',
                minutes : '00',
                seconds : '00'
            },
            interval    : 0,
            text_duration : '',
            count_timer : null,
            pause       : 0,
            stop        : 0,
        }
    },
    mounted() {
        this.startTimer();
        console.log(this.$translate);
    },
    methods: {
        countdownTimer: function() {
            if(this.current_time <= this.current_end) {
                var eventTime   = this.current_end;
                var currentTime = this.current_time;
                var diffTime    = eventTime - currentTime;
                var duration    = this.$moment.duration(diffTime*1000, 'milliseconds');
                var interval    = this.interval;

                duration = this.$moment.duration(duration - interval, 'milliseconds');

                let hours = duration.hours();
                if(hours < 10) {
                    hours = "0"+hours;
                }
                
                let minutes = duration.minutes();
                if(minutes < 10) {
                    minutes = "0"+minutes;
                }

                let seconds = duration.seconds();
                if(seconds < 10) {
                    seconds = "0"+seconds;
                }

                this.text_duration = `${hours + ":" + minutes + ":" + seconds}`;

                if(hours < 1) {
                    if(minutes < 1) {
                        if(seconds < 1) {
                            this.stopTimer();
                        }
                    }
                }
            } else {
                this.text_duration = "Waktu telah berakhir";
            }
        },
        pauseTimer: function(params=0) {
            if(params == 0) {
                this.pause = 1;
                clearInterval(this.count_timer);
            } else {
                this.pause = 0;
                this.startTimer();
            }
        },
        stopTimer: function() {
            this.stop     = 1;
            this.interval = 0;
            clearInterval(this.count_timer);
            this.text_duration = "Waktu telah berakhir";
        },
        startTimer: function() {
            this.stop     = 0;
            this.pause    = 0;
            this.count_timer = setInterval(() => {
                this.countdownTimer()
                this.interval = this.interval+1000;
            }, 1000);        
        }
    }
  }
  </script>
  