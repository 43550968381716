<template>
  <div class="about">
    Oeltima Experimental &copy; Copyright 2023 <a href="https://oeltimacreation.com" target="_blank">oeltimacreation.com</a>
  </div>
</template>

<script>
export default {
  name: 'AboutView',
  metaInfo: {
    title: 'About',
    titleTemplate: '%s - Oeltima Experimental'
  }
}
</script>
