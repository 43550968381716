import Vue from 'vue'
import App from './App.vue'
import router from './router'
import VueMeta from 'vue-meta'
import Vuelidate from 'vuelidate'
import axios from 'axios'
import VueSweetalert2 from 'vue-sweetalert2'
import 'sweetalert2/dist/sweetalert2.min.css'
import VuePageTransition from 'vue-page-transition'
import moment from 'moment'

Vue.config.productionTip    = false

Vue.prototype.$http         = axios
Vue.prototype.$apiconfig    = ''//process.env.VUE_APP_APIHOST
Vue.prototype.frontend_url  = ''//process.env.VUE_APP_FRONTEND
Vue.prototype.backend_url   = ''//process.env.VUE_APP_APIHOST
Vue.prototype.$moment       = moment

// Vue Use
Vue.use(VueMeta)
Vue.use(Vuelidate)
Vue.use(VueSweetalert2)
Vue.use(VuePageTransition)


import en from '../src/lang/en.js'
import id from '../src/lang/id.js'

Vue.prototype.$translate  = en;
Vue.prototype.$changeLanguange = function(params) {
  if(params == 'id') {
    Vue.prototype.$translate      = id;
  } else {
    Vue.prototype.$translate      = en;
  }
};


// Add Global Auth Token
// const token = localStorage.getItem('auth_token')
const token = 'EUWonCB07Vo1a2n0pXQopLxuK15OwOSV'
if (token) {
  axios.defaults.headers.common['Authorization'] = token
}

new Vue({
  router,
  render: h => h(App)
}).$mount('#app')