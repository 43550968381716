<template>
  <div class="home">
    This home page
  </div>
</template>

<script>
  export default {
    name: 'HomeView',
    metaInfo: {
      title: 'Home',
      titleTemplate: '%s - Oeltima Experimental'
    }    
  }
</script>
