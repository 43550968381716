<template>
  <div>
    <nav class="navbar navbar-expand-lg navbar-light bg-light">
      <a class="navbar-brand" href="#">
        <img src="https://oeltimacreation.com/images/logo.png" width="50" height="50" alt="">
      </a>

      <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNavAltMarkup"
        aria-controls="navbarNavAltMarkup" aria-expanded="false" aria-label="Toggle navigation">
        <span class="navbar-toggler-icon"></span>
      </button>
      <div class="collapse navbar-collapse" id="navbarNavAltMarkup">
        <div class="navbar-nav">
          <router-link class="nav-item nav-link" :class="activeClass == 'home' ? 'active':''" to="/"
            @click.native="setActive('home')">Home</router-link>
          <router-link class="nav-item nav-link" :class="activeClass == 'payment' ? 'active':''" to="/payment"
            @click.native="setActive('payment')">Payment</router-link>
          <router-link class="nav-item nav-link" :class="activeClass == 'timer' ? 'active':''" to="/timer"
            @click.native="setActive('payment')">Timer</router-link>
          <router-link class="nav-item nav-link" :class="activeClass == 'about' ? 'active':''" to="/about"
            @click.native="setActive('about')">About</router-link>
        </div>
        <!-- <div class="nav navbar-nav navbar-right">
            <button type="button" class="btn btn-info" v-on:click="changeLanguage('id')">Ganti Bahasa</button>
            <button type="button" class="btn btn-info" v-on:click="changeLanguage('en')">Ganti Bahasa</button>
        </div> -->
      </div>
    </nav>

    <div class="container p-3 my-3 border">
      <slot />
    </div>

  </div>
</template>

<script>
  export default {
    name: 'MainLayout',
    data() {
      return {
        activeClass: 'home'
      }
    },
    methods: {
      setActive: function(params = "") {
        this.activeClass = params
      },
      changeLanguage: function(params) {
        this.$parent.$parent.$changeLanguange(params);
      }
    }
  }
</script>