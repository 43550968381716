<template>
  <div class="container payment">
    <h5 class="text-center mt-3">Data Tagihan Mahasiswa
      <br/>
      Tahun Ajaran 2023/2024 Genap
    </h5>
    <div v-if="dataFetch" class="mt-3">
      <p>Loading...</p>
    </div>

    <div v-if="!dataFetch">
      <div v-for="(item, index) in dataList" :key="index">
        <table class="mt-5">
            <tr>
                <td>NPM</td>
                <td>:</td>
                <td>&nbsp;&nbsp;<b>{{ item.npm }}</b></td>
            </tr>
            <tr>
                <td>Nama</td>
                <td>:</td>
                <td>&nbsp;&nbsp;<b>{{ item.name }}</b></td>
            </tr>
        </table>
        
        <table class="table table-bordered mt-3">
          <thead class="thead-light">
            <tr>
              <th class="text-center" style="width:5%">#</th>
              <th>Nama Tagihan</th>
              <th>Harga</th>
              <th>Keterangan</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(itemDetail, indexDetail) in item.bill" :key="indexDetail">
                <td class="text-center">
                <div class="form-check">
                    <span v-if="itemDetail.paid == 1">-</span>
                    <input v-if="itemDetail.paid == 0" class="form-check-input" type="checkbox" :value="itemDetail" id="defaultCheck1" v-model="selected">
                </div>            
                </td>
                <td>{{ itemDetail.bill_name }}</td>
                <td class="text-right">{{ itemDetail.text_price }}</td>
                <td class="text-right">
                  <span v-if="itemDetail.paid == 1" class="badge badge-success">Lunas</span>
                  <span v-if="itemDetail.paid == 0" class="badge badge-danger">Belum Lunas</span>
                </td>
            </tr>
          </tbody>
          <tfoot>
            <tr>
              <td class="text-right" colspan="2">Total Keseluruhan</td>
              <td class="text-right font-weight-bold">{{item.text_total}}</td>
            </tr>
          </tfoot>
        </table>

        <h5 class="mb-4">Pilih Metode Pembayaran</h5>
        <div class="payment-methods">
          <div class="method" v-for="method in dataListMethods" :key="method.id" @click="selectMethod(item.users_id, method.code)" :class="{ 'selected': selectedMethod[item.users_id] === method.code }">
            <img :src="method.image" :alt="method.name">
            <div class="description">
              <h5>{{ method.name }}</h5>
              <p>Biaya Admin : {{ method.text_fee_admin }}</p>
            </div>
          </div>
        </div>
        <div class="text-center mt-4">
            <button :disabled="selectedMethod[item.users_id] == ''" type="button" class="btn btn-primary" v-on:click="continueToPayment(item)">
              {{ selectedMethod[item.users_id] == '' ? 'Pilih Metode Pembayaran':'Lanjutkan Pembayaran'}}
            </button>
        </div>
        <hr/>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'PaymentView',
    metaInfo: {
      title: 'Payment',
      titleTemplate: '%s - Oeltima Experimental'
    },
    data() {
        return {
            dataFetch   : true,
            dataFetchMethods   : true,
            dataList    : [],
            dataListMethods    : [],
            selected    : [],
            selectedMethod : []
        }
    },
    created() {
      this.getPaymentMethods();
      this.getData();
    },
    methods: {
        getPaymentMethods: function() {
          let uri         = process.env.VUE_APP_APIHOST+'transactions/payment-methods-list';
          this.dataFetchMethods  = true;                
          this.$http.get(uri).then(res => {
              this.dataFetchMethods  = false;                
              this.dataListMethods   = res.data.data;
          });
        },
        getData: function() {
          let uri         = process.env.VUE_APP_APIHOST+'bill/list';
          this.dataFetch  = true;                
          this.$http.get(uri).then(res => {
              this.dataFetch  = false;                
              this.dataList   = res.data.data;

              var tempDataPresence = {};

              this.dataList.forEach(element => {
                if(!tempDataPresence[element.users_id]) {
                    tempDataPresence[element.users_id] = ''; 
                }
              });

              this.selectedMethod   = tempDataPresence;
          });
        },
        selectMethod(users_id, methodId) {
          this.selectedMethod[users_id] = methodId;
        },
        continueToPayment: function(dataItem=null) {
            let item = this.selected;

            let bill = [];
            let html = "Tagihan yang kamu pilih adalah sebagai berikut:";
            html += "<ul style='text-align:left !important; margin-top: 10px !important'>";
            item.forEach(element => {
                html += "<li>"+element.bill_name+"</li>";
                
                bill.push({
                  'entity_id' : element.bill_id,
                  'entity_name' : element.bill_name,
                  'entity_price' : element.price,
                });
            });
            html += "</ul>";

            this.$swal({
                    icon: 'info',
                    title: 'Lanjut ke pembayaran?',
                    html: html,
                    confirmButtonText: 'Lanjutkan',
                    showCancelButton: true,
                    cancelButtonText: 'Batal',
                }).then((result) => {
                if (result.value) {
                    this.$http.post(process.env.VUE_APP_APIHOST + 'transactions/core-api/create-order', {
                        identity_number   : dataItem.npm,
                        fullname          : dataItem.name,
                        email             : dataItem.email,
                        callback_url      : 'https://paymentexperimental.oeltimacreation.com/payment/detail',
                        notification_url  : 'https://paymentmiddleware.oeltimacreation.com/notification-payment',
                        bill_item         : JSON.stringify(bill),
                        code_payment_methods : this.selectedMethod[dataItem.users_id]
                    })
                    .then(response => {
                        if (response.data.status) {
                            this.$swal({
                                icon: 'success',
                                title: 'Success',
                                text: response.data.message,
                                confirmButtonText: 'OK'
                            }).then((result) => {
                                if (result.value) {
                                    this.goToOrder(response.data.data.order_id);
                                }
                            });
                        } else {
                        this.$swal({
                            icon: 'warning',
                            title: 'Ups!',
                            text: response.data.message,
                        });
                        }
                    })
                }
            });
        },
        goToOrder: function(order_id=null) {
          this.$router.push({
              name: 'payment_detail', 
              query: { order_id: order_id }
          });
        }
    }
  }
</script>