import Vue from 'vue'
import VueRouter from 'vue-router'
import VueMeta from 'vue-meta'

import Home from '../views/Home.vue'
import About from '../views/About.vue'
import Timer from '../views/Timer.vue'
import Payment from '../views/Payment.vue'
import PaymentDetail from '../views/PaymentDetail.vue'

import MainLayout from '../layout/MainLayout.vue'

Vue.use(VueRouter)
Vue.use(VueMeta);

const routes = [
  {
    path: '/',
    name: 'home',
    component: Home,
    meta: {
      layout : MainLayout,
      transition: 'fade'
    }
  },
  {
    path: '/about',
    name: 'about',
    component: About,
    meta: {
      layout : MainLayout,
      transition: 'fade'
    }
  },
  {
    path: '/timer',
    name: 'timer',
    component: Timer,
    meta: {
      layout : MainLayout,
      transition: 'fade'
    }
  },
  {
    path: '/payment',
    name: 'payment',
    component: Payment,
    meta: {
      layout : MainLayout,
      transition: 'fade'
    }
  },
  {
    path: '/payment/detail',
    name: 'payment_detail',
    component: PaymentDetail,
    meta: {
      layout : MainLayout,
      transition: 'fade'
    }
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior (to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      return { x: 0, y: 0 }
    }
  }  
})

export default router