<template>
    <div class="PaymentDetail">
        <div v-if="dataFetch">
            <p>Loading...</p>
        </div>
        <div v-if="!dataFetch">
            <div class="row">
                <div class="col-md-6 offset-md-3">
                    <div class="card">
                        <div class="card-body text-center">
                            <h3 class="card-title">
                                <span class="badge badge-secondary" v-if="dataPayment.transactions_info.payment_status == 'choose_method'">Pilih Metode Pembayaran</span>
                                <span class="badge badge-primary" v-if="dataPayment.transactions_info.payment_status == 'pending'">Menunggu Pembayaran</span>
                                <span class="badge badge-success" v-if="dataPayment.transactions_info.payment_status == 'settlement'">Lunas</span>
                                <span class="badge badge-danger" v-if="dataPayment.transactions_info.payment_status == 'canceled'">Dibatalkan</span>
                                <span class="badge badge-danger" v-if="dataPayment.transactions_info.payment_status == 'expire'">Kadaluarsa</span>
                                <span class="badge badge-danger" v-if="dataPayment.transactions_info.payment_status == 'failure'">Transaksi gagal</span>
                            </h3>
                            <div v-if="dataPayment.transactions_info.payment_type == 'bank_transfer' || dataPayment.transactions_info.payment_type == 'echannel' || dataPayment.transactions_info.payment_type == 'mandiri'">
                                <p class="card-text"><strong>Metode Pembayaran:</strong> Bank Transfer</p>
                                <div class="d-flex justify-content-center align-items-center mb-3 mt-0">
                                    <img :src="'https://paymentmiddleware.oeltimacreation.com/'+dataPayment.transactions_info.bank+'.png'" alt="Bank Logo" class="bank-logo me-3" style="width:20%">
                                </div>
                                <p v-if="dataPayment.transactions_info.bank != 'mandiri'" class="card-text"><strong>Nomor Virtual Akun:</strong> {{ dataPayment.transactions_info.va_number }}</p>
                                <p v-if="dataPayment.transactions_info.bank == 'mandiri'" class="card-text">
                                <strong>Biller Code:</strong> {{ dataPayment.transactions_info.biller_code }}
                                <br/>
                                <strong>Bill Key:</strong> {{ dataPayment.transactions_info.bill_key }}
                                </p>
                            </div>
                            <div v-if="dataPayment.transactions_info.payment_type == 'gopay'">
                                <p class="card-text"><strong>Metode Pembayaran:</strong> QRIS</p>
                                <div class="d-flex justify-content-center align-items-center mb-3 mt-0">
                                    <img :src="'https://paymentmiddleware.oeltimacreation.com/gopay.png'" alt="Bank Logo" class="bank-logo me-3" style="width:20%">
                                    <img :src="'https://paymentmiddleware.oeltimacreation.com/qris.png'" alt="Bank Logo" class="bank-logo me-3" style="width:20%">
                                </div>
                                <p class="card-text"><strong>Pindai</strong></p>
                                <div v-if="fetchQrCode"> Sedang memuat qr code...</div>
                                <div class="d-flex justify-content-center align-items-center mb-3 mt-0" id="qrcode"></div>
                                <div v-if="!fetchQrCode">
                                    <br/>
                                    atau 
                                    <a :href="dataPayment.transactions_info.actions[1].url" target="blank">klik disini</a> untuk melakukan pembayaran
                                    <br/>
                                    <br/>
                                </div>
                            </div>
                            <p class="card-text"><strong>Total Pembayaran:</strong> {{dataPayment.transactions_info.item_total_price}}</p>
                            <p class="card-text"><strong>Batas Pembayaran:</strong> {{ dataPayment.transactions_info.expired_at }}</p>
                            <button class="btn btn-payment btn-primary" type="button" data-toggle="collapse" data-target="#kontenCollapse" aria-expanded="false" aria-controls="kontenCollapse">
                                Lihat Cara Pembayaran
                            </button>

                            <!-- Konten yang akan di-collapse -->
                            <div class="collapse" id="kontenCollapse" v-if="dataPayment.transactions_info.payment_steps">
                                <div class="card text-left card-body" v-for="(item, index) in dataPayment.transactions_info.payment_steps" :key="index">
                                    <b>{{item.name}}</b>
                                    <ul class="mt-3" v-for="(itemDetail, indexDetail) in item.steps" :key="indexDetail">
                                        <li>{{ itemDetail }}</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <h5 class="mb-4">Detail Tagihan</h5>
            <hr/>
            <table>
                <tr>
                    <td>NPM</td>
                    <td>:</td>
                    <td>&nbsp;&nbsp;<b>{{ dataPayment.users_info.identity_number }}</b></td>
                </tr>
                <tr>
                    <td>Nama</td>
                    <td>:</td>
                    <td>&nbsp;&nbsp;<b>{{ dataPayment.users_info.fullname }}</b></td>
                </tr>
            </table>

            <table class="table table-bordered mt-3">
                <thead class="thead-light">
                    <tr>
                    <th class="text-center" style="width:5%">#</th>
                    <th>Nama Tagihan</th>
                    <th>Harga</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(itemDetail, indexDetail) in dataPayment.transactions_info.item" :key="indexDetail">
                        <td class="text-center">
                        <div class="form-check">
                            {{ ++indexDetail }}
                        </div>
                        </td>
                        <td>{{ itemDetail.entity_name }}</td>
                        <td class="text-right">{{ itemDetail.entity_price }}</td>
                    </tr>
                </tbody>
            </table>
            <!--div class="text-center">
                <button class="btn btn-primary btn-sm" type="button" v-on:click="openSnap(dataPayment.midtrans_snap)">
                    {{dataPayment.transactions_info.payment_status == 'choose_method' ? 'Pilih Metode Pembayaran':'Lihat Pembayaran'}}
                </button>
            </div-->
        </div>
    </div>
  </template>
  
  <script>
  export default {
    name: 'PaymentDetail',
    metaInfo: {
      title: 'Status Order',
      titleTemplate: '%s - Oeltima Experimental'
    },
    data() {
        return {
            dataFetch   : true,
            fetchQrCode : true,
            dataPayment    : [],
        }
    },    
    created() {
      this.getData();
    },
    mounted() {
        let externalScript = document.createElement('script')
        externalScript.setAttribute('src', 'https://app.sandbox.midtrans.com/snap/snap.js')
        externalScript.setAttribute('data-client-key', 'SB-Mid-server-3Q8A4Yw85GOaOD8wPzp8WMWH')
        externalScript.setAttribute('async', true)
        document.head.appendChild(externalScript)
    },
    methods: {
        getData: function() {
          let uri         = process.env.VUE_APP_APIHOST+'transactions/order';
          this.dataFetch  = true;                
          this.$http.get(uri, {
            params : {
                order_id : this.$route.query.order_id
            }
          }).then(res => {
              if(res.data.status) {
                this.dataFetch  = false;                
                this.dataPayment   = res.data.data;
                this.fetchQrCode = false;
                if(this.dataPayment.transactions_info.payment_type == 'gopay') {
                    this.fetchQrCode = true;
                    setTimeout(() => {
                        const url = this.dataPayment.transactions_info.actions[0].url;

                        // Create an image element for the QR code
                        const qrCodeImg = document.createElement("img");
                        qrCodeImg.src = `https://api.qrserver.com/v1/create-qr-code/?size=200x200&data=${encodeURIComponent(url)}`;
                        qrCodeImg.alt = "QR Code";

                        // Append the image to the qrcode container
                        document.getElementById("qrcode").appendChild(qrCodeImg);                    
                        this.fetchQrCode = false;
                    }, 1000);
                }
              } else {
                this.$swal({
                    icon: 'error',
                    title: 'Failed',
                    text: res.data.message,
                    confirmButtonText: 'OK'
                }).then((result) => {
                    if (result.value) {
                        this.$router.push({
                            name: 'payment'
                        });
                    }
                });
              }
          });
        },
        openSnap: function(token) {
          window.snap.pay(token, {
              onSuccess: function () {
                  location.reload();
              },
              onPending: function () {
                  location.reload();
              },
              onError: function () {
                  location.reload();
              }
          });
        }
    }
  }
  </script>
  